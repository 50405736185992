import { Box, ButtonBase, MenuItem, Popover, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { H6, Small } from "../../../components/Typography";
import useAuth from "../../../hooks/useAuth";
import { updateRegion } from "../../../services/userService";
import { COUNTRIES_LIST, SnackbarVariant } from "../../../utils/constants";

// styled components
const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  padding: 5,
  marginLeft: 4,
  borderRadius: 30,
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": { backgroundColor: theme.palette.action.hover },
}));

const ItemWrapper = styled(Box)(() => ({
  display: "flex",
  "& img": { width: "100%" },
}));

const RegionPopover: FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { user, updateUserInfo } = useAuth();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeRegion = (country: any) => {
    setOpen(false);
    updateRegion(country.countryCode).then((dataR) => {
      if (dataR) {
        enqueueSnackbar(t("saved"), {
          variant: SnackbarVariant.success,
        });
        window.location.replace(country.urlFe);
        updateUserInfo();
      } else {
        enqueueSnackbar(t("msg_07"), {
          variant: SnackbarVariant.error,
        });
      }
    });
  };

  return (
    <>
      <StyledButtonBase disableRipple ref={anchorRef} onClick={handleOpen}>
        <Small mx={1} color="text.secondary">
          Country{" "}
          <Small fontWeight="600" display="inline">
            {user?.location ? user.location : COUNTRIES_LIST[0].name}
          </Small>
        </Small>
      </StyledButtonBase>

      <Popover
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{ sx: { width: 150, padding: "0.5rem 0" } }}
      >
        {COUNTRIES_LIST.map((country: any) => (
          <MenuItem
            key={country.countryCode}
            onClick={() => handleChangeRegion(country)}
          >
            <ItemWrapper>
              <H6 fontWeight={600} ml={1}>
                {`${country.name}, ${country.region}`}
              </H6>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default RegionPopover;
