import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      manager: "Manager",
      practice: "Practice",
      training_list: "Training List",
      exam_list: "Exam List",
      report: "Report",
      company_list: "Company List",
      download_practise: "Download Practise",
      student: "Student",
      admin: "Admin",
      practises_for_training: "Practises for training",
      category: "Category",
      target: "Target",
      all: "All",
      id: "Id",
      name: "Name",
      type: "Type",
      progressing: "Progressing",
      result: "Result",
      passed: "Passed",
      action: "Action",
      have_not_target: "Have not target!",
      error: "Error!",
      have_not_data: "have not data!",
      start: "START",
      re_start: "RE-START",
      start_exam: "START EXAM",
      training_report: "Training report",
      date: "Date",
      is_exam: "Is exam",
      phone: "Phone",
      adress: "Adress",
      description: "Description",
      company_detail: "Company detail",
      create_company: "Create company",
      back_to_list: "Back to list",
      update_company: "Update company",
      public_key: "Public key",
      secret_key: "Secret key",
      company_name: "Company name",
      company_email: "Company email",
      company_phone: "Company phone",
      company_adress: "Company adress",
      keyboards_permission: "Keyboards permission",
      languages_permission: "Languages permission",
      user_list: "User list",
      add_user: "Add User",
      roles: "Roles",
      language: "Language",
      keyboard: "Keyboard",
      deleted: "Deleted!",
      image_name: "Image name",
      status: "Status",
      practise_list: "PRACTISE LIST",
      index: "Index",
      downloaded: "Downloaded",
      not_download: "Not Download",
      first_name: "First name",
      last_name: "Last name",
      user_profile: "User profile",
      back: "Back",
      email: "Email",
      profile_keyboard: "Profile & Keyboards",
      sign_out: "Sign Out",
      time: "Time",
      stop: "Stop",
      practise_case: "Practise case",
      sub_category: "SubCategory",
      loading: "loading",
      correct: "Correct",
      in_Correct: "In-Correct",
      search_for: "Search for",
      forbiden_items: "Forbiden items",
      documentation: "Documentation",
      practise_result: "Practise result",
      cancel: "Cancel",
      session_duration: "Session duration",
      overview: "Overview",
      overall: "Overall",
      clear_images: "Clear images",
      item_images: "Item images",
      images_analyzed: "Images analyzed",
      correct_responses: "Correct responses",
      incorrect_responses: "Incorrect responses",
      detail_result: "Detail Result",
      image: "Image",
      total_markers: "Total markers",
      wrong_markers: "Wrong markers",
      result_markers: "Result markers",
      object: "Object",
      time_allocated: "Time Allocated",
      time_used: "Time Used",
      identified: "identified",
      show_content: "Show content",
      content_image: "Content image",
      preview_practise: "Preview Practise",
      return: "Return",
      main_information: "Main information",
      confirm: "Confirm",
      yes: "Yes",
      no: "No",
      go_user_list: "Go user list",
      incorrect_markers: "Incorrect markers",
      correct_markers: "Correct markers",
      practise_information: "Practise information",
      case: "Case",
      return_to_list: "Return to list",
      summary_report: "Summary report",
      summary: "Summary",
      time_line: "Time line",
      total_exam_bags: "Total exam bags",
      total_exercise_time: "Total exercise time",
      exercise_time: "Exercise time",
      hours: "Hours",
      exam: "Exam",
      score: "Score",
      bags: "Bags",
      failed: "failed",
      result_list: "Result list",
      training_Exam_result: "Training or exam result",
      feedback: "Feedback",
      feedback_detail: "Feedback detail",
      practise_name: "Practise name",
      practise_category: "Practise category",
      practise_type: "Practise type",
      practise_desc: "Practise desc",
      company_feedback: "Company feedback",
      user_feedback: "User feedback",
      have_new_version: "Have new version",
      trainer_and_exam_list_active: "Practise access list for",
      notifications: "Notifications",
      no_notifications: "There are no notifications",
      update_practise: "Update practise",

      msg_01: "Please input key before get data",
      msg_02: "Get Company from content system",
      msg_03: "Categry and target group permission",
      msg_04: "Clean all test result",
      msg_05:
        "Note: the system will be deleted all results exam in your company",
      msg_06: "Download practise for company",
      msg_07: "Some thing wrong!",
      msg_08: "Error check download!",
      msg_09: "Error check practise valid!",
      msg_10: "Practise download Error!",
      msg_11: "Updated status!",
      msg_12: "Error to update status!",
      msg_13: "Some error for downloading practise again",
      msg_14: "IMAGE ERROR LIST",
      msg_15:
        "Forbidden items in cabin luggage are for example: Firearms Knives Blunt instruments Workmen tools Axes Carwing knives",
      msg_16: "Everage processing time per images",
      msg_17: "Do you want to remove all results of exam?",
      msg_18: "Total score",
      msg_19: "Markers or image correct/total",
      msg_20: "Download data for testing",
      msg_21: "Do you want to delete this user?",
      msg_22: "Do you want to delete this company?",
      msg_23: "Feedback practise data",
      msg_24: "Please select some option of feedback",
      msg_25:
        "Indication text is not corresponding image / Wrong indication text",
      msg_26: "X-ray image and live image not corresponding",
      msg_27: "Image too difficult",
      msg_28: "Image too easy",
      msg_29: "Other",
    },
  },
  fi: {
    translation: {
      manager: "Hallinta",
      practice: "Harjoitteet",
      training_list: "Harjoitukset",
      exam_list: "Kokeet",
      report: "Raportti",
      company_list: "Yritykset",
      download_practise: "Lataa harjoitus",
      student: "Oppilas",
      admin: "Hallinta",
      practises_for_training: "Harioitukset",
      category: "Kategoria",
      target: "Kohderyhmä",
      all: "Kaikki",
      id: "ID",
      name: "Nimi",
      type: "Laji",
      progressing: "Edistysminen",
      result: "Tulos",
      passed: "Keskeytetty",
      action: "Suorita",
      have_not_target: "Have not target!",
      error: "Error!",
      have_not_data: "Data puuttuu!",
      start: "ALOITA",
      re_start: "RE-START",
      start_exam: "ALOITA KOE",
      training_report: "Raportointi",
      date: "PVM",
      is_exam: "Koe",
      phone: "Puhelin",
      adress: "Osoite",
      description: "Kuvaus",
      desc: "Kuvaus",
      company_detail: "Yrityksen tiedot",
      create_company: "Luo yritys",
      back_to_list: "Takaisin listaukseen",
      update_company: "Päivitä yritys",
      public_key: "Public key",
      secret_key: "Secret key",
      company_name: "Yrityksen nimi",
      company_email: "Yrityksen email",
      company_phone: "Yrityksen puhelin",
      company_adress: "Yrityksen osoite",
      keyboards_permission: "Näppäimistöt",
      languages_permission: "Kielet",
      user_list: "Käyttäjä listaus",
      add_user: "Lisää Käyttäjä",
      roles: "Roolit",
      language: "Kieli",
      keyboard: "Näppäimistö",
      deleted: "Deleted!",
      image_name: "Kuvan nimi",
      status: "Status",
      practise_list: "Harjoitukset",
      index: "Index",
      downloaded: "Ladattu",
      not_download: "Ei ladattu",
      first_name: "Etunimi",
      last_name: "Sukunimi",
      user_profile: "Käyttäjä profili",
      back: "takaisin",
      email: "Email",
      saved: "Tallennettu!",
      profile_keyboard: "Profili ja nappäimistö",
      sign_out: "Kirjaudu ulos",
      time: "Aika",
      stop: "Stop",
      practise_case: "Harjoitus",
      sub_category: "Ala-kategoria",
      loading: "Ladataan",
      correct: "Oikein",
      in_Correct: "Väärin",
      search_for: "Etsi",
      forbiden_items: "Kielletty esine",
      documentation: "Asiakirjat",
      practise_result: "Harjoitus tulos",
      cancel: "Peruuta",
      session_duration: "Harjoituksen kesto",
      overview: "Katsaus",
      overall: "Totaali",
      clear_images: "Puhdas kuva",
      item_images: "Kohde kuvat",
      images_analyzed: "Analysoidut kuvat",
      correct_responses: "Oikeat vastaukset",
      incorrect_responses: "Väärät vastaukset",
      detail_result: "Tulos",
      image: "Kuva",
      total_markers: "Yhteensä merkintöjä",
      wrong_markers: "Vääriä merkintöjä",
      result_markers: "Merkinnät, tulos",
      object: "Objekti",
      time_allocated: "Sallittu aika",
      time_used: "Käytetty aika",
      identified: "Tunnistettu",
      show_content: "Näytä sisältö",
      content_image: "Sisältö kuva",
      preview_practise: "Preview Practise",
      return: "Return",
      main_information: "Main information",
      confirm: "Confirm",
      yes: "Yes",
      no: "No",
      go_user_list: "Go user list",
      incorrect_markers: "Incorrect markers",
      correct_markers: "Correct markers",
      practise_information: "Harjoitukset",
      case: "Harjoitus",
      return_to_list: "Palaa",
      summary_report: "Yhteenveto",
      summary: "Yhteenveto",
      time_line: "Ajanjakso",
      total_exam_bags: "Koe laukkuja",
      total_exercise_time: "Harjoitusaika yhteenstä",
      exercise_time: "Harjoitusaika",
      hours: "Tuntia",
      exam: "Koe",
      score: "Tulos",
      bags: "Laukkuja",
      failed: "Hylätty",
      result_list: "Tulos listaus",
      training_Exam_result: "Harjoitus tai koe tulos",
      feedback: "Palaute",
      feedback_detail: "Feedback detail",
      practise_name: "Practise name",
      practise_category: "Practise category",
      practise_type: "Practise type",
      practise_desc: "Practise desc",
      company_feedback: "Company feedback",
      user_feedback: "User feedback",
      have_new_version: "Have new version",
      trainer_and_exam_list_active: "Practise access list for",
      notifications: "Tiedote",
      no_notifications: "Ei ole tiedotteita",
      update_practise: "Päivitä harjoitus",

      msg_01: "Syötä avain jotta saat dataa",
      msg_02: "Hae yritys content systeemistä",
      msg_03: "Kategoria ja kohderyhmä luvat",
      msg_04: "Poista kaikki testi data",
      msg_05: "Järjestelmä tuhoaa kaikki testi datat yrityksestä",
      msg_06: "Lataa harjoitus yritykselle",
      msg_07: "Jotain meni vikaan!",
      msg_08: "Error, tarkista lataus!",
      msg_09: "Error, tarkista harjoituksen validiteetti!",
      msg_10: "Harjoitus, lataus error!",
      msg_11: "Status päivitetty!",
      msg_12: "Error, päivitys virhe!",
      msg_13: "Erroreita harjoituksen uudelleen lataamisessa",
      msg_14: "Virhelistaus kuvat",
      msg_15:
        "Käsimatkatavarassa kiellettyjä esineitä ovat muun muassa: veitset, tuliaseet, lyömävälineet…",
      msg_16: "Keskimääräinen kuvankäsittely aika",
      msg_17: "Do you want to remove all results of exam?",
      msg_18: "Total score",
      msg_19: "Markers or image correct/total",
      msg_20: "Lataa data",
      msg_21: "Haluatko poistaa käyttäjän?",
      msg_22: "Haluatko poistaa yrityksen?",
      msg_23: "Harjoitteen palaute",
      msg_24: "Valitse aihe",
      msg_25: "Teksti ei vastaa kuvaa",
      msg_26: "Läpivalaisukuva ja valokuva eivät täsmää",
      msg_27: "Kuva liian vaikea",
      msg_28: "Kuva liian helppo",
      msg_29: "Muu, mitä",
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});
